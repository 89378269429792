var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { MAINTAIN_PERMISSION, CONTAINER_FILLING_ITEM } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-item" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("div", { staticClass: "px-2" }, [_c("a-row", { staticClass: "header mb-5 px-4 pb-3", attrs: { "gutter": 16, "type": "flex" } }, [_c("a-col", { staticClass: "d-flex align-items-center flex-wrap", attrs: { "span": 20 } }, [_c("a-icon", { staticClass: "arrow-left mr-3", attrs: { "type": "arrow-left" }, on: { "click": function($event) {
    return _vm.onBack();
  } } }), _c("span", { staticClass: "header-title" }, [_vm._v("Item")]), _vm.entity && _vm.entity.code ? _c("span", { staticClass: "ml-1 mr-3 header-title" }, [_vm._v(_vm._s(" - " + _vm.entity.code))]) : _vm._e(), _vm.entity && _vm.entity.description ? _c("span", { staticClass: "entity-color" }, [_vm._v(_vm._s(_vm.entity.description))]) : _vm._e()], 1)], 1)], 1), _c("div", { staticClass: "body mx-5" }, [_c("a-row", [_c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-row", { attrs: { "gutter": [24, 24], "type": "flex" } }, [_c("a-col", { attrs: { "md": 24, "lg": 8 } }, [_c("a-card", { staticClass: "h-100" }, [_c("a-spin", { attrs: { "spinning": _vm.isLoading } }, [_c("a-row", { attrs: { "gutter": [0, 16] } }, [_c("a-col", { attrs: { "span": 24 } }, [_c("text-input", { attrs: { "disabled": "", "form-item": "", "label": "Item Code", "value": _vm.editForm.code } })], 1), _c("a-col", { attrs: { "span": 24 } }, [_c("text-input", { attrs: { "disabled": "", "form-item": "", "label": "Description", "value": _vm.editForm.description } })], 1), _c("a-col", { attrs: { "span": 24 } }, [_c("text-input", { attrs: { "disabled": "", "form-item": "", "label": "Stock UoM", "value": _vm.editForm.stockUoM } })], 1), _c("a-col", { attrs: { "span": 24 } }, [_c("cf-select-input", { attrs: { "form-item": "", "rules": "required", "label": "Default Status", "placeholder": "Choose Status", "reference": "cf.common.status", "source": "id", "source-label": "description", "search-by": "Description", "filter-type": "StartsWith", "option-filter": _vm.filterIsInactiveOption, "api-not-get-paging": "", "all-in-value": "", "disabled": !_vm.can(_vm.permissions.maintain), "value": _vm.editForm.statusDesc }, on: { "change": function($event) {
      return _vm.storeValue("statusId", $event.id);
    } } })], 1), _c("a-col", { attrs: { "span": 24 } }, [_c("div", { staticClass: "neutral-7--text" }, [_vm._v(" Last changed "), _vm.editForm.updatedBy ? _c("span", [_vm._v("by")]) : _vm._e(), _c("br"), _vm.editForm.updatedBy ? _c("span", [_vm._v(_vm._s(_vm.entity.updatedBy))]) : _vm._e(), _vm._v(" on " + _vm._s(_vm._f("moment")(_vm.entity.updatedAt, "DD/MM/YYYY - hh:mm A")) + " ")])])], 1)], 1)], 1)], 1), _c("a-col", { attrs: { "md": 24, "lg": 16 } }, [_c("a-card", { staticClass: "h-100" }, [_c("a-spin", { attrs: { "spinning": _vm.isLoading } }, [_c("a-row", { attrs: { "gutter": [16, 16] } }, [_c("a-col", { attrs: { "span": 8 } }, [_c("text-input", { attrs: { "form-item": "", "label": "Specification", "value": _vm.editForm.specificationCode, "disabled": true } })], 1), _c("a-col", { attrs: { "span": 16 } }, [_c("text-input", { attrs: { "form-item": "", "label": "Specification Description", "value": _vm.editForm.specificationDescription, "disabled": true } })], 1), _c("a-col", { attrs: { "span": 8 } }, [_c("text-input", { attrs: { "form-item": "", "rules": "required|max:10", "label": "Label Description 1", "placeholder": "Type Label Description 1", "value": _vm.editForm.labelDesc1, "disabled": !_vm.can(_vm.permissions.maintain), "max-length": 10 }, on: { "change": function($event) {
      return _vm.storeValue("labelDesc1", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 16 } }, [_c("text-input", { attrs: { "form-item": "", "rules": "max:15", "label": "Label Description 2", "placeholder": "Type Label Description 2", "value": _vm.editForm.labelDesc2, "disabled": !_vm.can(_vm.permissions.maintain), "max-length": 15 }, on: { "change": function($event) {
      return _vm.storeValue("labelDesc2", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 8 } }, [_c("cf-select-input", { attrs: { "form-item": "", "rules": "required", "label": "Container Type", "placeholder": "Choose Container Type", "reference": "cf.common.cont-types", "search-by": "Code", "source": "id", "source-label": "code", "filter-type": "StartsWith", "delete-filter": "IsInactive", "queries": _vm.containerTypeQueries, "is-del-query-string-in-search": "", "api-not-get-paging": "", "all-in-value": "", "value": _vm.editForm.containerTypeId, "disabled": !_vm.can(_vm.permissions.maintain) }, on: { "change": _vm.onContainerTypeChange } })], 1), _c("a-col", { attrs: { "span": 16 } }, [_c("text-input", { attrs: { "form-item": "", "label": "Container Type Description", "value": _vm.editForm.containerTypeDescription, "disabled": true } })], 1), _c("a-col", { attrs: { "span": 8 } }, [_c("auto-complete-input", { attrs: { "form-item": "", "label": "Customer", "placeholder": "Choose Customer", "reference": "cf.common.customers", "source": "customerNumber", "search-by": "CustomerNumber", "filter-type": "StartsWith", "delete-filter": "IsInactive", "cols": _vm.colCustomers, "api-not-get-paging": "", "all-in-value": "", "disabled": !_vm.can(_vm.permissions.maintain), "value": _vm.editForm.customerNo }, on: { "searchValueChange": _vm.onCustomerSearchValueChange, "change": _vm.onCustomerChange } })], 1), _c("a-col", { attrs: { "span": 16 } }, [_c("text-input", { attrs: { "disabled": "", "label": "Customer Name", "value": _vm.editForm.customerName } })], 1), _c("a-col", { attrs: { "span": 24 } }, [_c("switch-input", { attrs: { "form-item": "", "label": "Create Suspend Sample", "value": _vm.editForm.suspendSample, "span": 24 }, on: { "change": function($event) {
      return _vm.storeValue("suspendSample", $event);
    } } })], 1)], 1)], 1)], 1)], 1)], 1), _c("a-divider"), _vm.can(_vm.permissions.maintain) ? _c("a-row", { attrs: { "type": "flex", "justify": "end" } }, [_c("a-button", { attrs: { "disabled": _vm.isLoading }, on: { "click": _vm.onBack } }, [_vm._v("Cancel")]), _c("a-button", { staticClass: "ml-2", attrs: { "type": "primary", "disabled": _vm.isLoading, "loading": _vm.isLoading }, on: { "click": function($event) {
      return handleSubmit(_vm.onSave);
    } } }, [_vm._v("Save")])], 1) : _vm._e()];
  } }]) })], 1)], 1)], 1);
};
var staticRenderFns$1 = [];
var EditItem_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$1 = {
  name: "EditItem",
  inject: ["resourceProps", "can"],
  data() {
    const [itemProps, qmiManufacProps, qpmSpecProps] = this.resourceProps;
    return {
      containerTypeKeyword: "",
      editForm: {},
      isLoading: true,
      itemProps,
      qmiManufacProps,
      qpmSpecProps,
      colCustomers: [{ name: "customerName", span: 18 }],
      itemsMenu: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "item",
          title: "Item",
          path: "/container-filling/item"
        }
      ],
      permissions: {
        maintain: MAINTAIN_PERMISSION
      },
      allowCustomItemsMenu: true
    };
  },
  computed: {
    containerTypeQueries() {
      const { containerTypeId } = this.entity;
      if (!containerTypeId)
        return null;
      return [["currentContTypeId", containerTypeId]];
    },
    entity() {
      return this.itemProps.crud.getEntity() || {};
    }
  },
  watch: {
    entity(newVal) {
      if (!newVal || this._.isEmpty(newVal))
        return;
      if (this.allowCustomItemsMenu)
        this.customItemsMenu(newVal);
      this.storeValues(newVal);
    }
  },
  async created() {
    try {
      this.isLoading = true;
      await this.itemProps.crud.fetchEntity();
    } catch (err) {
      this.onBack();
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    customItemsMenu({ code }) {
      this.allowCustomItemsMenu = false;
      this.itemsMenu.push({
        key: code,
        title: code,
        path: code
      });
    },
    filterIsInactiveOption(option) {
      return !option.isInactive;
    },
    storeValue(resourceKey, value) {
      this.editForm = __spreadProps(__spreadValues({}, this.editForm), {
        [resourceKey]: value
      });
      this.itemProps.crud.updateEntity("editForm", {
        resourceKey,
        value
      });
    },
    storeValues(entity) {
      Object.entries(entity).forEach(([resourceKey, value]) => this.storeValue(resourceKey, value));
    },
    async onSave() {
      try {
        this.isLoading = true;
        await this.itemProps.crud.submitEntity("replace");
      } finally {
        this.isLoading = false;
      }
    },
    onBack() {
      this.$router.push("/container-filling/item");
    },
    onContainerTypeChange(value) {
      if (!value) {
        this.storeValue("containerTypeId", null);
        this.storeValue("containerTypeDescription", null);
      }
      const { id, description } = value;
      this.storeValue("containerTypeId", id);
      this.storeValue("containerTypeDescription", description);
    },
    onCustomerChange(value) {
      if (!value) {
        this.storeValue("customerName", "");
        this.storeValue("customerAddress1", "");
        this.storeValue("customerAddress2", "");
        return;
      }
      this.storeValue("customerNo", value.customerNumber);
      this.storeValue("customerName", value.customerName);
      this.storeValue("customerAddress1", value.address1);
      this.storeValue("customerAddress2", value.address2);
    },
    onCustomerSearchValueChange(value) {
      this.storeValue("customerNo", value);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "b604766e", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditItem = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "create-item" }, [_c("resource", { attrs: { "name": "cf.common.cont-types", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "cf.common.status", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "cf.common.customers", "api-url": _vm.apiUrl, "resource-id-name": "customerNumber" } }), _c("resource", { attrs: { "page": _vm.page, "name": ["cf.items", "cf.common.qmi-manufac", "cf.common.qpm-spec"], "api-url": _vm.apiUrl, "redirect-route": "/container-filling/item", "resource-id-name": ["id", "specCode", "specCode"] } }, [_c("edit-item")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditItem
  },
  data() {
    return {
      apiUrl,
      page: CONTAINER_FILLING_ITEM
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
